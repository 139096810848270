import React from "react"
import Layout from "../../components/layout"
import { Link } from "gatsby"
import SEO from "../../components/seo"
import ProseDark from "../../components/prose-dark"
import ProseLight from "../../components/prose-light"

class ElectionPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      start: false,
      loading: true
    };
  }

  componentDidMount() {
    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    sleep(100).then(() => {
        this.setState({ loading: false });
    })
    sleep(300).then(() => {
        this.setState({ start: true });
    })

    // if (this.video) {
    //   this.video.addEventListener("loadeddata", () => {
    //     this.setState({ loading: false });
    //   });
    // }
  }

  componentWillUnmount() {
    // if (this.video) {
    //   this.video.removeEventListener("loadeddata", () => {
    //   });
    // }
  }
  
  render() {

  return(
  <Layout>
    <SEO title="Election Services" />
    <div className="bg-midnight w-full px-8 py-36">
    <div className="m-auto w-full container">
      <ProseDark>
        <div className="grid md:grid-cols-2 gap-12">

        <div>
        <div className="text-base mb-4 -mt-4">
          <Link className="inline-block no-underline hover:text-yellow-400 hover:underline text-white font-normal" to="/">Home</Link>
          <span className="opacity-50"> <svg xmlns="http://www.w3.org/2000/svg" className="inline-block h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
          <path strokeLinecap="round" strokeLinejoin="round" d="M17 8l4 4m0 0l-4 4m4-4H3" /></svg> Election Services</span>
        </div>
        <h1 className="text-5xl md:text-6xl text-yellow-400 leading-none">Election Services</h1>
        </div>
        <div>

        <p>Elections are a necessary part of special purpose district management.</p>
        <p>As a utility district, your election may be to authorize bonds for WS&D, parks, or roads; or, you may require an increase in your Maintenance & Operation rate. As an emergency services district, you may need to increase your ad valorem or sales tax within the district boundaries. In either case, your residents won’t support a proposition without understanding what it addresses for the District and the community.
</p>
        </div>
        </div>
      </ProseDark>
    </div>
    </div>
    <div className="bg-zinc-100 w-full px-8 pb-36">
    <div className="m-auto w-full container">
      <ProseLight>
        <iframe className="relative bottom-20 mx-auto w-full lg:w-2/3 aspect-video rounded-3xl shadow-2xl" src="https://www.youtube.com/embed/ktZ3uNTLI3c?rel=0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"></iframe>

        <div className="m-auto w-full lg:w-2/3 mt-12 leading-loose">

        {/* <h1 className="text-7xl leading-none text-center">Elections</h1> */}
        <p className="font-medium">Touchstone understands elections, and we understand the hurdles that can influence the results of your next initiative. We are constantly reevaluating our approach to district elections to be efficient with taxpayer funds and maximize the potential for success. With the reality of COVID-19, we have implemented new digital offerings for public educational events to address the safety concerns of public gatherings.</p>

        <p className="font-bold text-midnight text-2xl">Touchstone can actively monitor social media and neighborhood groups; if a post goes “viral” about your proposition we are able to address it with factual and relevant information quickly. We can work “boots on the ground” in your neighborhoods and district to educate the residents with small events and community discussions. </p>
        <p className="font-bold text-midnight text-2xl">When other companies rely solely on a website to inform the residents, they can’t reach the voters effectively.</p>

        <p className="font-medium">Every proposition is unique, as are the needs of your district. The earlier you engage us, the more we can accomplish in each phase. Ideally, a district should begin this process a year in advance of their target election date; however, we have successfully completed election education in shorter windows of time.</p>

        <p className="font-medium">No matter how soon your election is to be held, schedule an election consultation and see what we can do for you.</p>
        <p className="text-center">
          <Link className="transition-all inline-block rounded-full px-6 py-2 bg-yellow-500 hover:bg-yellow-400 text-black no-underline" to="/contact-us">Let's Schedule a Meeting</Link>
        </p>
        </div>

      </ProseLight>
    </div>
    </div>
  </Layout>
)
}
}

export default ElectionPage
